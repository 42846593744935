import axios from "axios";
import { message } from "ant-design-vue";
import { getToken, removeToken } from "@/utils/auth";
// import { createVNode } from 'vue';
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import globalConfig from "./config";
// import store from '@/store'
import router from "@/router";
const baseURL = globalConfig.baseUrl;
const service = axios.create({
  baseURL: baseURL,
  // withCredentials: true,
  timeout: 15000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!config.data) {
      config.data = {};
    }
    if (getToken()) {
      config.headers["t"] = getToken();
      config.headers["source"] = "admin";
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
let status = true;
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.status === 200) {
      // 登录过期
      if (res.code === "402" && status) {
        status = false;
        // Modal.confirm({
        //   title: '您的登录已过期,请重新登录！',
        //   okText: '重新登录',
        //   cancelText: '取消',
        //   icon: createVNode(ExclamationCircleOutlined),
        //   content: createVNode('div', {
        //     style: 'color:red;',
        //   }, ' '),
        //   onOk() {
        removeToken();
        router.replace("/login");
        //   }, onCancel() {
        //     console.log('Cancel');
        //   }
        // })
        // return Promise.reject(new Error(res.message || "Error"));
        return res;
      } else {
        if (res.code != "200" && res.code != "402") {
          message.error(res.message);
        } else if (
          res.code === "402" &&
          response.config.url === "/sysUser/logout"
        ) {
          removeToken();
          router.replace("/login");
        }
        return res;
      }
    }
  },
  (error) => {
    console.log("err" + error);
    message.error(error.message);
    return Promise.reject(error);
  }
);
declare module "axios" {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<any>;
  }
}
export default service;
