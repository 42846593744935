
import { createApp } from 'vue';
import router  from './router';
import Antd from 'ant-design-vue';
import App from './App.vue';
import 'ant-design-vue/dist/antd.css'
import Vconsole from 'vconsole'
// new Vconsole()

const app = createApp(App);

app.use(Antd)
app.use(router)
app.mount('#app');
