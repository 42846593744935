<template>
  <a-config-provider :locale="locale">
    <router-view></router-view
  ></a-config-provider>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, defineComponent, ref } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
const locale = ref(zhCN);
// import DataCenter from "./views/DataCenter.vue"
const DataCenter = defineAsyncComponent(() => import("./views/DataCenter.vue"));
// export default defineComponent({
//   components:{
//     DataCenter
//   },
//   setup() {

//     return {};
//   },
// });
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html {
  width: 100% !important;
  /* height: 100% !important; */
}
</style>
