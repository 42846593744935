export function ts_to_time(timestamp: string | number | Date) {
    if (typeof timestamp === "string") {
        timestamp = Number(timestamp);
    }
    if (typeof timestamp !== "number") {
        alert("输入参数无法识别为时间戳");
    }
    let date = new Date(timestamp);
    let Y = date.getFullYear() + "-";
    let M =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    let D = date.getDate() + " ";
    let h =
        date.getHours() < 10
            ? "0" + date.getHours() + ":"
            : date.getHours() + ":";
    let m =
        date.getMinutes() < 10
            ? "0" + date.getMinutes() + ":"
            : date.getMinutes() + ":";
    let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
};
